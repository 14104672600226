export const FAQsList = [
  {
    summary: "Which movies or shows can we watch? Can we play our own videos?",
    details:
      "Yes, you can connect your OTT accounts or phone through Chromecast and play content of your choice. Please note that we do NOT provide any movies or shows or OTT account. We just do the setup for you using your own content and accounts. We don't support pendrives and laptops for casting or watching movies.",
  },
  {
    summary:
      "Illegal/Prohibited contents are not allowed. We do not take any liability for such cases.",
    details:
      "Customers must not stream any content which is illegal/prohibited as per Indian laws. We will not be liable for any consequences that arise out of violation of this condition and we will not allow such customers to book with us again.",
  },
  {
    summary: "Refund Policy",
    details:
      "Hours Before the slot time. No refund for bookings which are less than 72 hrs from payment time.",
  },
  {
    summary:
      "In case of any technical interruption from our end, appropriate refund will be provided.",
    details:
      "If there is any failure in working of the projector, laptop, internet access or sound system - we will reimburse the paid amount after deducting the rent for the period of time before interruption.",
  },
  {
    summary: "You are responsible for your belongings",
    details:
      "We will not be responsible in case of loss of personal belongings of any nature. Customers must take care of their belongings.",
  },
  {
    summary: "The booking period includes set up and check out time",
    details:
      "Customers must vacate the theater along with all their belongings on or before the end time.",
  },
  {
    summary:
      "Customers must not make any changes to the configuration of technical equipment.",
    details:
      "Customers should not make adjustments or modifications to the set up of projector, screen, laptop and sound system. If any changes is required, we will do it from our end.",
  },
  {
    summary: "Couples below 18 years of age are not allowed to book the theater",
    details:
      "Aadhaar card/DL is checked for all bookings and underage couples are not permitted to book. Minor group bookings are permitted.",
  },
  {
    summary:
      "Customers will be liable to pay in case of any damage to the theater caused by them",
    details:
      "If there is any damage to any of the technical or non technical item in the theater including the walls, lights, seating, etc. the customers will have to pay for it.",
  },
  {
    summary:
      "Cleaning fee up to Rs 1000 will be charged in cases where significant cleaning would be required after check out",
    details:
      "In cases where cleaners would be required to clean the garbage, left overs and other wastes after the customers check out, the customers will have to pay appropriate cleaning fee.",
  },
  {
    summary: "Restricted consumption",
    details:
      "Customers are not allowed to smoke, consume alcoholic beverages or any illegal substance inside the theater. Appropriate action would be taken against such customers.",
  },
  {
    summary: "Pets are strictly not allowed inside the theatre",
    details: "Pets are strictly not allowed inside the theatre",
  },
  {
    summary: "What about Photography and Fog entry?",
    details: "Photoshoot and Fog Entry can be added online while booking. You can also add these On-Spot on day of booking.",
  },
  {
    summary: "Are the seats recliners?",
    details: "Small theaters with up to 4 seating capacity have all recliner seating while bigger theaters with more than 4 people seating capacity have recliners on the front and non-recliner sofas on the back.",
  },
  {
    summary: "What about food and beverages? Is outside food allowed?",
    details: "All our branches have delicious food and beverages menu which differs from branch to branch. You can also carry your own food or order food online. We will provide cutlery (chargeable).",
  },
  {
    summary: "Are washrooms available?",
    details: "Yes all our branches have neat and tidy washrooms.",
  },
  
  {
    summary: "Are Decorations Customizable?",
    details: "Decorations will be as per the photos and videos shown in website. Essential customization and personalization like Neon Lights, LED Name Board, etc. will be done as per the chosen occasion. Balloon colors are not Customizable",
  },
];
