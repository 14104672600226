import styled from "styled-components";
import Banner from "../../Home/screens/Banner/Banner";
import CopyRight from "../../Home/screens/CopyRight/CopyRight";
import Footer from "../../Home/screens/Footer/Footer";
import Header from "../../Home/screens/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { getRefundPolicyPage } from "../../../redux/selectors/webConfigSelector";
import { useEffect, useState } from "react";
import { fetchWebConfig } from "../../../apis/ConfigAPI";
import { setWebConfig } from "../../../redux/actions/webConfigActions";
import { Helmet, HelmetProvider } from "react-helmet-async";
import seoStrings from "../../../seoSrings";
import { MerchantReturnPolicy } from "schema-dts";
import { helmetJsonLdProp } from "react-schemaorg";

const RefundPolicyContainer = styled.div`
  display: flex;
  flex-direction: column;

  // laptop
  @media only screen and (min-width: 710px) {
    padding: 6rem 9rem;
  }

  // tablet and mobile
  @media only screen and (max-width: 1110px) {
    padding: 2.5rem 1.5rem;
  }
`;

const Heading = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const CustomerReviewDesc = styled.div`
  margin: 1rem 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media only screen and (max-width: 786px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const RefundPolicy = () => {
  const dispatch = useDispatch();
  const refundPolicyPage = useSelector(getRefundPolicyPage);
  const { heading = "", description = "" } = refundPolicyPage || {};

  const [error, setError] = useState("");

  useEffect(() => {
    if (!refundPolicyPage || Object.keys(refundPolicyPage).length === 0) {
      fetchWebConfig()
        .then((response) => {
          dispatch(setWebConfig(response));
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, []);

  return (
    <>
    <HelmetProvider>
      <Helmet
        script={[
          helmetJsonLdProp<MerchantReturnPolicy>({
            "@context": "https://schema.org",
            "@type": "MerchantReturnPolicy",
            "name": "Binge Town Refund Policy",
            "description": "Learn about our website's refund policy page.",
            "url": "https://thebingetown.com/refund_policy"
          }),
        ]}
      >
        <title>{seoStrings['refundPolicy']['title']}</title>
        <meta
                name="keywords"
                content={seoStrings['refundPolicy']['keyword']}
            />
      </Helmet> 
      </HelmetProvider>
      <Header />
      <Banner />

      <RefundPolicyContainer>
        <Heading>{heading}</Heading>
        <CustomerReviewDesc>{description}</CustomerReviewDesc>
      </RefundPolicyContainer>

      <Footer />
      <CopyRight />
    </>
  );
};
export default RefundPolicy;
