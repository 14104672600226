import styled from "styled-components";

export const ContactUsPageContainer = styled.div`
  display: flex;
  flex-direction: column;

  // laptop
  @media only screen and (min-width: 710px) {
    padding: 6rem 9rem;
  }

  // tablet and mobile
  @media only screen and (max-width: 786px) {
    padding: 2rem 1.5rem;
  }
`;

export const Heading = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const RightHalf = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CustomerReviewDesc = styled.div`
  margin: 1rem 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;

export const LeftHalf = styled.div`
  justify-content: flex-end;
  flex: 1;
  display: flex;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const LeftFirstHalf = styled.div`
  justify-content: flex-end;
  flex: 1;
  display: none;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`;

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ReachUsDetails = styled.div`
  display: flex;
  padding: 0.3rem 0;
  align-items: center;
`;

export const ReachUsText = styled.div`
  padding-left: 1rem;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const ReachUsIcon = styled.div`
  display: flex;
  justify-content: center;
`;
