import styled from "styled-components";

export const TheatersContainer = styled.div`
  border: 1px solid #cacaca;
  border-radius: 24px;

  margin: 1rem 0;
  
  @media only screen and (max-width: 786px) {
    margin: 1rem 0 0.5rem 0 ;
  }
`;

export const TheaterView = styled.div`
  display: flex;

  @media only screen and (max-width: 786px) {
    flex-direction: column;
  }
`;

export const LeftView = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const CarouselView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  flex: 1;
  top: -2rem;
  position: relative;
`;

export const ViewSampleVideoContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 32px;
  background-color: #ffffff;
  padding: 0.1rem 0.5rem;
  top: 2rem;
  left: 2rem;
  position: relative;
  width: 24.5%;
  gap: 0.5rem;
  z-index: 10;

  @media only screen and (max-width: 786px) {
    width: 32%;
    top: 1rem;
    left: 1rem;
    position: relative;
  }
`;

export const ViewSampleVideoIcon = styled.div``;

export const ViewSampleVideoTxt = styled.div`
  flex: 1;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;

export const RightView = styled.div`
  flex: 1;
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media only screen and (max-width: 786px) {
    padding: 0 1rem;
  }
`;

export const FooterView = styled.div`
  padding: 1rem;

  @media only screen and (max-width: 786px) {
    padding: 0 1rem;
  }
`;

export const TimeSlots = styled.div`
  @media only screen and (max-width: 786px) {
    font-size: 12px
  }
`;

export const TimeTxt = styled.div`
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  @media only screen and (max-width: 786px) {
    font-size: 12px
  }
`;

export const TimeSlotLabel = styled.div`
  margin: 0.5rem 0rem;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

export const MwebTimeSlotLabel = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

export const TimeSlotsAvailable = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const MwebTimeSlotsAvailable = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  flex: 1;
  display: none;
  @media only screen and (min-width: 786px) {
    display: none;
    margin: 0rem;
  }
`;

export const BookingAction = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 1.1rem;

  @media only screen and (max-width: 786px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  margin-right: 1rem;

  @media only screen and (max-width: 786px) {
    font-size: 18px;
    font-weight: 600;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;  
`;

export const PriceContainer = styled.div``;
export const PriceContainerText = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Price = styled.span`
  font-size: 24px;
  font-weight: 500;

  @media only screen and (max-width: 786px) {
    font-size: 18px;
  }
`;

export const ExtraChargeContainer = styled.span`
  font-size: 24px;
  font-weight: 700;
`;

export const ExtraChargePrice = styled.span`
  font-size: 10px;
  font-weight: 600;
`;

export const ExtraChargeText = styled.span`
  font-size: 10px;
  font-weight: 400;
`;

export const TaxInclude = styled.span`
  font-size: 15px;
  font-weight: 400;
  line-height: 38px;

  @media only screen and (max-width: 786px) {
    font-size: 13px;
    line-height: 27px;
  }
`;

export const MaxLimit = styled.div`
  padding: 0.4rem 1rem;
  background-color: #e9e3f5;
  border-radius: 24px;
`;

export const MoreDetails = styled.div`
  font-size: 14px;
  gap: 0.5rem;
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;

  @media only screen and (max-width: 786px) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0.5rem;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  font-size: 14px;
`;

export const MoreInfoAboutSlots = styled.div`
  flex-wrap: wrap;
`;

export const SlotsAvailable = styled.div`
  font-size: 14px;
  background-color: #1c9b21;
  padding: 0.3rem 1rem;
  border-radius: 24px;
  color: white;
  font-weight: 600;

  @media only screen and (max-width: 786px) {
    padding: 0.2rem 0.8rem;
  }
`;

export const MinSlotsAvailable = styled.div`
  font-size: 14px;
  background-color: #ff5208;
  padding: 0.3rem 1rem;
  border-radius: 24px;
  color: white;
  font-weight: 600;

  @media only screen and (max-width: 786px) {
    padding: 0.2rem 0.8rem;
  }
`;

export const CurrentViewers = styled.div`
  font-size: 14px;
  color: #ff5208;
  background-color: #ffe6db;
  padding: 0.5rem 1rem;
  border-radius: 24px;
  font-weight: 500;

  @media only screen and (max-width: 786px) {
    padding: 0.5rem 0.3rem;
  }
`;

export const Details = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  gap: 1rem;
  display: flex;
`;

export const MoreDtail = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  gap: 1rem;
`;

export const AddressLabel = styled.div`
  font-weight: 500;
  font-size: 14;
  line-height: 17px;
`;

export const AddressText = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const Address = styled.div``;

export const AddressDirection = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PeopleInfo = styled.div`
  display: flex;
  gap: 0.2rem;
  flex-wrap: wrap;

  @media only screen and (max-width: 786px) {
    font-size: 12px;
  }
`;

export const TipContainer = styled.div`
  @media only screen and (max-width: 786px) {
    font-size: 12px;
    line-height: 14.63px;
    text-align: center;
  }
`;
