import styled from "styled-components";

export const CopyRightContainer = styled.div`
  background-color: black;
  color: white;
  padding: 1.2rem 9rem;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  flex-shrink: 0;
  @media only screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1.2rem 1rem;
  }
`;

export const CopyRightTxt = styled.div`
  flex: 2;
  @media only screen and (max-width: 1000px) {
    border-bottom: 1px solid white;
    padding: 1.2rem 1rem;
    text-align: center;
  }
`;

export const CopyRightsLinks = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;

  @media only screen and (max-width: 1000px) {
    // margin-top: 1rem;
    padding: 1.2rem 1rem;
    display: flex;

    flex-wrap: wrap;
    gap: 1rem;
  }
`;
