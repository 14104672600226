export const DecorationList = {
  "Rose Heart": {
    id: 1,
    name: "Red Heart",
  },
  "Candle Path": {
    id: 2,
    name: "Candle Path",
  },
  "LED Numbers": {
    id: 3,
    name: "LED Numbers",
  },
};
