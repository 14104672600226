import styled from "styled-components";
import Banner from "../../Home/screens/Banner/Banner";
import CopyRight from "../../Home/screens/CopyRight/CopyRight";
import Footer from "../../Home/screens/Footer/Footer";
import Header from "../../Home/screens/Header/Header";
import ArticleCard from "./components/ArticleCard";
import { HiArrowLeft } from "react-icons/hi";
import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getArticleInfo } from "../../../redux/selectors/blogsSelector";
import { setArticles } from "../../../redux/actions/blogsActions";
import { PROD } from "../../../endPointsConfig";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;

  // laptop
  @media only screen and (min-width: 710px) {
    padding: 6rem 9rem;
  }

  // tablet and mobile
  @media only screen and (max-width: 1110px) {
    padding: 2.5rem 1.5rem;
  }
`;

const Heading = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 40px;
  padding: 2rem 0rem;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 0rem;
  }
`;

const CustomerReviewDesc = styled.div`
  margin: 1rem 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;

  @media only screen and (max-width: 786px) {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
  }
`;

export const BackButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  // justify-content: center;
  align-items: center;
  gap: 0.5rem;
  // padding: 1.5rem 0;
`;

export const BackButtonTxt = styled.div``;

const Article = () => {
  const navigate = useNavigate();
  let { blogId } = useParams();

  const dispatch = useDispatch();
  const articleInfo = useSelector(getArticleInfo) || {};
  const { heading, imgURL, subHeading, articles = [], metaDescription, } = articleInfo || {};
  const [error, setError] = useState("");

  useEffect(() => {
    if (Object.keys(articleInfo).length > 0) return;

    fetch(`${PROD}/getBlogs?blogId=${blogId}`)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        dispatch(setArticles(response));
      })
      .catch((err) => {
        setError(err);
      });
  }, [articleInfo, blogId]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>{heading}</title>
            <meta name="description" content={ metaDescription ? metaDescription : subHeading}/> 
            <meta
                    name="keywords"
                    content={`Private movie theaters for celebration, Birthday celebration places, Places for celebrations, Birthday decoration at home, Birthday balloon decoration, ${heading}`}
                />
        </Helmet>
      </HelmetProvider>
      <Header />
      <Banner />
      <ArticleContainer>
        <BackButtonContainer onClick={() => navigate(-1)}>
          <HiArrowLeft size={24} />
          <BackButtonTxt>Back</BackButtonTxt>
        </BackButtonContainer>
        <Heading><h1>{heading}</h1></Heading>
        <CustomerReviewDesc>{subHeading}</CustomerReviewDesc>
        {articles &&
          articles.length > 0 &&
          articles.map((article: any) => {
            return (
              <ArticleCard
                key={article.heading.slice(0, 7)}
                title={article.heading}
                imgURL={article.imgURL || article?.imgUrl}
                description={article.description}
                source={article.source}
              ></ArticleCard>
            );
          })}
      </ArticleContainer>

      <Footer />
      <CopyRight />
    </>
  );
};
export default Article;
