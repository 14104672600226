import styled from "styled-components";

export const CustomerReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 6rem 9rem;

  @media only screen and (max-width: 786px) {
    padding: 2rem 1.5rem;
    display: none;
  }
`;

export const MwebCustomerReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 786px) {
    // gap: 2rem;
    padding: 2rem 1.5rem;
  }
  @media only screen and (min-width: 786px) {
    display: none;
  }
`;

export const Heading = styled.div`
  font-size: 40px;
  font-weight: 600;
  line-height: 48.76px;
  margin-bottom: 4rem;

  @media only screen and (max-width: 786px) {
    margin: 0;
    font-size: 24px;
    line-height: 30px;
    // padding: 2rem 1.5rem;
  }
`;

export const CustomerReviewText = styled.div`
  // padding-top: 3rem;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;

  @media only screen and (max-width: 786px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const CustomerReviewBy = styled.div`
  margin: 1rem 0;
  font-size: 24px;
  font-weight: 700;
  padding-top: 1rem;
  line-height: 30px;

  @media only screen and (max-width: 786px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  // padding: 2rem;

  // @media only screen and (max-width: 768px) {
  //   flex-direction: column;
  // }
`;

export const Review = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eaecfa;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  padding: 2rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
    margin: 2rem 0;
    height: 11rem;
  }
`;
