import styled from "styled-components";

export const BookingOverviewContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const LeftViewSectionContainer = styled.div`
  flex: 2.5;
  padding: 2rem 0 2rem 2rem;

  @media only screen and (max-width: 786px) {
    padding: 1.5rem;
  }
`;

export const RightViewSectionContainer = styled.div`
  flex: 1.5;
  // background-color: #edecea;

  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

export const Overview = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;

  @media only screen and (max-width: 1000px) {
    margin-top: 1.5rem;
  }
`;

export const CakeView = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  margin-top: 2rem;
  @media only screen and (max-width: 500px) {
    justify-content: space-between;
    gap: 0rem;
    margin-top: 1rem;
  }
`;

export const OccasionView = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin: 2rem;

  @media only screen and (max-width: 500px) {
    margin: 1rem 0;
    width: 40%;
  }
`;

export const OccasionTitle = styled.section`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0.5rem;

  @media only screen and (max-width: 786px) {
    margin: 0.2rem;
    text-align: center;
  }
`;

export const TickImageContainer = styled.img`
  position: relative;
  top: 35%;
  left: 35%;
  z-index: 100;

  @media only screen and (max-width: 500px) {
    left: 30%;
    top: 30%;
  }
`;

export const OccasionPrice = styled.section`
  // display: flex;
`;

export const BackButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const BackButtonTxt = styled.div``;

export const HeadingContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const HeadingTxt = styled.div`
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;

  @media only screen and (max-width: 1000px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const OptionalContainer = styled.div`
  display: flex;
  // justify-content: flex-end;
  align-items: flex-end;
`;

export const MwebFooterContainer = styled.div`
  display: none;
  @media only screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;

export const PriceBreakupSummaryContainer = styled.div`
  display: none;

  @media only screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 1px solid #e3e2e0;
    border-radius: 16px;
    margin: 2rem 0;
  }
`;

export const PriceBreakupHeader = styled.div`
  display: none;

  @media only screen and (max-width: 1000px) {
    display: flex;
    background-color: #e3e2e0;
    flex: 1;
    // padding: 0.7rem 0;
    border-radius: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const PriceBreakup = styled.div`
  display: none;

  @media only screen and (max-width: 1000px) {
    display: flex;
    flex: 1;
    margin: 1.5rem 0.7rem;
  }
`;

export const PriceBreakupTotal = styled.div`
  display: none;

  @media only screen and (max-width: 1000px) {
    display: flex;
    flex: 1;
    margin: 1.5rem 0.7rem;
  }
`;

export const PriceBreakupPrice = styled.div`
  display: none;

  @media only screen and (max-width: 1000px) {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin: 1.5rem 0.7rem;
  }
`;

export const BookingSummaryHeading = styled.div`
  display: flex;
  flex: 1;
`;

export const BookingSummaryTxt = styled.div`
  display: flex;
  flex: 1;
  text-decoration: underline;
  color: #34255c;
`;

export const BookNowBtn = styled.button`
  display: flex;
  justify-content: center;
  background-color: #34265c;
  color: white;
  border: none;
  border-radius: 64px;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  padding: 1rem;
  font-family: Montserrat;
`;

export const PriceBreakupDetails = styled.div`
  flex: 1;
`;
