import styled from "styled-components";
export const MyBookingOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6rem 9rem;

  gap: 1rem;
  @media only screen and (max-width: 1210px) {
    padding: 2.5rem 1.5rem;
  }
`;

export const Heading = styled.div`
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 786px) {
    display: flex;
    flex-direction: column;
    padding-bottom: 0rem;
  }
`;

export const HeadingTxt = styled.div`
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;

  display: flex;
  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 0rem;
  }
`;

export const BookingsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: space-between;
  gap: 1rem;

  @media only screen and (max-width: 786px) {
    // flex-direction: column;
    gap: 1rem;
    flex: 1;
    // margin: 1rem;
    // padding: 2rem;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BookingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 24rem;
  height: 40rem;
  row-gap: 1rem;
  border-radius: 16px;
  padding: 2rem;

  box-shadow: 0px 10px 35px 0px #575c8a1f;

  @media only screen and (max-width: 786px) {
    width: 17rem;
    height: 34rem;
  }
  @media only screen and (max-width: 350px) {
    width: 15rem;
    height: 34rem;
    padding: 1rem;
  }
`;

export const ButtonView = styled.button`
  background-color: #34265c;
  color: white;
  border-radius: 64px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  padding: 1rem;
  border: none;
  font-family: "Montserrat";
`;

export const ImgContainer = styled.div`
  background-color: #d9d9d9;
  border-radius: 20px;
  height: 18rem;
  margin: 1rem 0;

  @media only screen and (max-width: 786px) {
    height: 30rem;
    margin: 0.5rem 0;
  }
  @media only screen and (max-width: 350px) {
    height: 30rem;
  }
`;

export const TheaterContainer = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

export const DateContainer = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  // align-items: flex-end;
`;

export const IconContainer = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-right: 1rem;
`;

export const Date = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #747688;
  display: flex;
  // justify-content: flex-end;
`;

export const CancelBookingContainer = styled.a`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #34255c;
  display: flex;
  justify-content: center;
  text-decoration: underline;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 0rem;
  flex: 1;
`;

export const ModalHeading = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  justify-content: space-between;
`;

export const ModalHeading1 = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  justify-content: center;
`;

export const ModalHeadingTxt = styled.div``;

export const ModalDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

export const ModalSubTxt = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: stretch;
`;

export const BookNowBtn = styled.button`
  display: flex;
  justify-content: center;
  background-color: ${(props) => (props.disabled ? "#E5E5E5" : "#34265c")};
  color: ${(props) => (props.disabled ? "#000000" : "#ffffff")};
  border: none;
  border-radius: 64px;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  padding: 1rem;
  font-family: Montserrat;
  gap: 1rem;
  height: 4rem;
  @media only screen and (max-width: 786px) {
    height: 3rem;
  }
`;
