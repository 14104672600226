import styled from "styled-components";

export const GalleryPageContainer = styled.div`
  display: flex;
  flex-direction: column;

  // laptop
  @media only screen and (min-width: 710px) {
    padding: 6rem 9rem;
  }

  // tablet and mobile
  @media only screen and (max-width: 1110px) {
    padding: 2.5rem 1.5rem;
  }
`;

export const Heading = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 1rem;
  }
`;

export const RightHalf = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CustomerReviewText = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  // border: 1px solid black;
`;

export const CustomerReviewDesc = styled.div`
  margin: 1rem 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
`;

export const LeftHalf = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  // padding: 6rem 0 0 0;
`;

export const ImgContainer = styled.div`
  width: 24rem;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  border-radius: 20px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const TopHalf = styled.div`
  background-color: #d9d9d9;
  flex: 2;
  border-radius: 20px 20px 0 0;
`;

export const BottomHalf = styled.div`
  background-color: #edecea;
  flex: 1;
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  gap: 0.5rem;
`;

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  // border: 1px solid black;
  flex: 2;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const MoreInfoContainer = styled.div`
  // border: 1px solid black;
  flex: 1;
  display: flex;
  gap: 0.5rem;
`;

export const ReadText = styled.div`
  flex: 0.2;
  text-decoration: underline;
  // @media only screen and (max-width: 768px) {
  //   font-size: 18px;
  //   line-height: 22px;
  // }
`;

export const RightArrow = styled.div`
  flex: 1;
  top: -2px;
  position: relative;
`;

export const CategoryContainer = styled.div`
  display: flex;

  @media only screen and (max-width: 786px) {
    flex-direction: column;
  }
`;
